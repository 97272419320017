<template>
  <transition-group tag="div" class="tariffs-grid l-flex" name="bubble">
    <template v-for="(vps, index) in lazyList">
      <div :key="index" class="l-col-12_md-6">
        <tariff-card :tariff="vps" @click-more="$emit('click-more', vps)" />
      </div>
    </template>
    <mugen-scroll
      v-show="hasNext"
      key="scroll"
      :handler="showNext"
      :should-handle="hasNext"
      class="l-col-12"
    >
      <base-loader />
    </mugen-scroll>
  </transition-group>
</template>

<script>
import TariffCard from './TariffCard';
import MugenScroll from 'vue-mugen-scroll';
export default {
  name: 'TariffsGrid',
  components: { TariffCard, MugenScroll },
  props: {
    dataset: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cnt: 10,
      step: 10,
    };
  },
  computed: {
    list() {
      return this.$store.state.moduleClouds.list;
    },
    lazyList() {
      return this.list.slice(0, this.cnt);
    },
    hasNext() {
      return this.list.length > this.cnt;
    },
  },
  methods: {
    onCardClick(payload) {
      this.$emit('click-more', payload);
    },
    showNext() {
      this.cnt += this.step;
    },
  },
};
</script>

<style></style>
