<template>
  <main-card class="tariff-card">
    <template #header>
      <router-link
        :to="{ name: 'tariffClouds', params: { id: tariff.id } }"
        class="tariff-card__title-high big-title"
      >
        {{ tariff.title }}
      </router-link>
      <div class="tariff-card__ip note-text">
        {{ tariff.ip }}
      </div>
    </template>
    <template #headerAction>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="bottom-end"
        :popper-options="popperOptions"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-card__popover"
      >
        <plain-button icon="more" class="tariff-card__more" @click="togglePopoverState" />
        <clouds-context-menu slot="popover" :tariff="tariff" class="tariff-card__context-menu" />
      </v-popover>
    </template>
    <template #headerEnd>
      <main-label :color="tariff.status.color" class="tariff-card__label">
        {{ $t(`status.${tariff.status.key}`) }}
      </main-label>
    </template>

    <div class="tariff-card__content">
      <div class="tariff-card__title medium-title">
        {{ tariff.expiredate }}
      </div>
      <div class="tariff-card__activity">
        <div class="tariff-card__note note-text">
          {{ $t(`created`, { date: createdate }) }}
        </div>
        <plain-button v-if="isProlongAvailable" color="primary" class="tariff-card__prolong">
          {{ $t('prolong') }}
        </plain-button>
        <plain-button color="primary" class="tariff-card__prolong" @click="goToWindow()">
          {{ $t('panel') }}
        </plain-button>
      </div>
    </div>
    <template #footer>
      <tariff-spec v-bind="tariff.specs" />
    </template>
  </main-card>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard.vue';
import MainLabel from '@/components/Labels/MainLabel.vue';
import TariffSpec from './TariffSpec';
import CloudsContextMenu from './CloudsContextMenu';
import popoverFix from '@/mixins/popoverFix';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
export default {
  name: 'TariffCard',
  components: { MainCard, MainLabel, TariffSpec, CloudsContextMenu },
  mixins: [popoverFix],
  props: {
    tariff: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      popperOptions: {
        modifiers: {
          flip: {
            padding: { top: 60 },
          },
        },
      },
    };
  },
  computed: {
    isProlongAvailable() {
      return this.tariff.prolong_available === 'on';
    },
    createdate() {
      return this.$d(this.tariff.createdate, 'short');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.popperOptions.modifiers.flip.boundary = this.$parent.$el;
    });
    if (this.$parent.$children.findIndex(i => i === this) < 2) {
      this.popperOptions.modifiers.flip.enabled = false;
    }
  },
  methods: {
    goToWindow() {
      let modal = null;
      this.$modals.open({
        name: 'ToPleskPanel',
        component: BaseLoader,
        closable: false,
        onOpen: inst => (modal = inst),
        onClose: () => (modal = null),
      });
      this.$store
        .dispatch('moduleClouds/fetchBillMgrToolAction', {
          func: 'gotoserver',
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            this.$modals.close();
            window.open(data.ok.v);
          }
        });
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "created": "Создан {date}",
    "panel": "Перейти в панель",
    "prolong": "Продлить"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-card {
  min-height: 100%;

   &__title {
    text-decoration: none;
    color: inherit;

    &-high {
      text-decoration: none;
      color: $primary-color;

      &:hover {
        color: darken($primary-color, 20%);
      }
    }
  }
  &__ip {
    margin-top: 2px;
    +breakpoint(sm-and-up) {
      margin-top: 20px;
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      margin-top: 0.25rem;
    }
  }
  &__more {
    display: block;
    margin-right: -0.5rem;

    +breakpoint(sm-and-up) {
      margin-right: -1rem;
      margin-top: -2px;
    }
  }
  &__activity {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
  }
  &__prolong {
    +breakpoint(ms-and-down) {
      margin-top: 0.75rem;
    }

    +breakpoint(sm-and-up) {
      margin-left: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 3.25rem);

    +breakpoint(sm-and-up) {
      width: 260px;
    }
  }
}
</style>
