<template>
  <div class="tariff-spec">
    <div v-if="os.img" class="tariff-spec__os-logo l-hidden-ms-down">
      <asset-img :src="os.img" :alt="os.name" class="tariff-spec__os-logo-img" />
    </div>
    <div class="tariff-spec__content">
      <!--      <div class="tariff-spec__os-name medium-title">-->
      <!--        {{ os.name }}-->
      <!--      </div>-->
      <base-specs :list="list" class="standart-text" />
    </div>
  </div>
</template>

<script>
import BaseSpecs from '@/components/Specs/BaseSpecs';
import specsFormatting from '../../../mixins/specsFormatting';
export default {
  name: 'TariffSpec',
  components: { BaseSpecs },
  mixins: [specsFormatting],
  props: {
    os: {
      type: Object,
      default: () => ({}),
    },
    cost: {
      type: Number,
      required: true,
      //validator: obj => isNumber(obj.value) && obj.unit,
    },
    datacentername: {
      type: String,
      default: '',
    },
    // disc: {
    //   type: Object,
    //   required: true,
    //   validator: obj => obj instanceof BillMgrMeasure,
    // },
    // mem: {
    //   type: Object,
    //   required: true,
    //   validator: obj => obj instanceof BillMgrMeasure,
    // },
    // cpu: {
    //   type: [Object],
    //   required: true,
    //   validator: obj => obj instanceof BillMgrMeasure,
    // },
  },
  computed: {
    list() {
      let arr = [];
      if (this.datacentername) arr.push(this.datacentername);
      // if (this.cpu) arr.push(this.cpu.toString());
      // if (this.mem) arr.push(this.mem.toString());
      // if (this.disc) arr.push(this.disc.toString());
      if (this.cost || this.cost === 0) arr.push(this.$n(this.cost, 'currency', this.$i18n.locale));
      return arr;
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-spec {
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &__os-logo {
    flex: 0 0 auto;
    +breakpoint(sm-and-up) {
      margin-right: 1.5rem;
    }
  }
  &__os-name {
    margin-bottom: 0.25rem;
  }
}
</style>
