<template>
  <base-table-row
    class="tariffs-table-item"
    :columns="columns"
    v-bind="item"
    col-class="tariffs-table-item__col"
  >
    <!-- <template #check>
      <base-checkbox :value="item.id" :model-value="checked" @change="$emit('checked', $event)" />
    </template> -->
    <template #server>
      <router-link
        :to="{ name: 'tariffClouds', params: { id: item.id } }"
        class="tariffs-table-item__link medium-text"
      >
        {{ item.pricelist }}
      </router-link>
    </template>
    <template #id>
      <div class="tariffs-table-item__ip note-color">
        {{ item.id }}
      </div>
    </template>
    <template #datacentername>
      <div class="tariffs-table-item__ip note-color">
        {{ item.datacentername }}
      </div>
    </template>
    <template #createdate>
      <div class="tariffs-table-item__ip note-color">
        {{ $d(item.createdate, 'short') }}
      </div>
    </template>
    <template #period>
      <div class="tariffs-table-item__ip note-color">
        {{ item.expiredate }}
      </div>
    </template>
    <template #cost>
      <div class="tariffs-table-item__cost note-color">
        {{ $n(item.cost, 'currency', $i18n.locale) }}
      </div>
    </template>
    <template #status>
      <main-label :color="item.status.color" theme="plain" class="tariffs-table-item__status">
        {{ $t(`status.${item.status.key}`) }}
      </main-label>
    </template>
    <template #more>
      <v-popover
        v-click-outside="closePopoverOnOutside"
        placement="left"
        :open="isPopoverOpen"
        trigger="manual"
        popover-class="tariff-table-item__popover"
      >
        <plain-button icon="more" class="tariffs-table-item__more" @click="togglePopoverState" />
        <clouds-context-menu
          slot="popover"
          :tariff="item"
          class="tariff-table-item__context-menu"
        />
      </v-popover>
    </template>
  </base-table-row>
</template>

<script>
import BaseTableRow from '@/components/Table/BaseTableRow';
import MainLabel from '@/components/Labels/MainLabel.vue';
//import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import CloudsContextMenu from './CloudsContextMenu';
import popoverFix from '@/mixins/popoverFix';
import { format } from 'date-fns';
export default {
  name: 'TariffsTableRow',
  components: {
    BaseTableRow,
    MainLabel,
    //BaseCheckbox,
    CloudsContextMenu,
  },
  mixins: [popoverFix],
  props: {
    columns: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedEndDate() {
      return format(this.item.expiredate, 'dd.MM.yyyy');
    },
  },
  methods: {
    getLocalizedSize(val, units) {
      const GB = 1024;
      let num = val;
      let unitKey = units === 'МиБ' ? 'mb' : units;
      if (unitKey === 'mb' && val >= GB) {
        unitKey = 'gb';
        num = num / GB;
      }
      return this.$t(unitKey, { num });
    },
    concatString(obj) {
      let str = obj.value;
      if (obj.unit) str += ` ${obj.unit}`;
      return str;
    },
    printParam(param, payload) {
      return param.toString(payload);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "activeUnder": "До {date} г.",
    "mb": "{num} МБ",
    "gb": "{num} ГБ"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariffs-table-item {
  font-size: 1rem;
  >>> &__col {
    vertical-align: middle;

    +breakpoint(md-and-down) {
      min-width: min-content;
      max-width: unset!important;
    }

    +breakpoint(ml-and-up) {
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }

    &:hover {
      background: var(--main-card-bg);
      text-overflow: unset;
      overflow: visible;
      z-index: 2;
    }
  }
  &__link {
    position: relative;
    padding: 0.5em;
    margin: -0.5em;
    color: $primary-color;
    text-decoration: none;
    white-space: nowrap;
    background: inherit;
    &:hover {
      color: darken($primary-color, 20%);
    }

    &:hover::after {
      +breakpoint(ml-and-up) {
        content: '';
        display: block;
        size(2.5em, 100%);
        absolute(0px, 100%);
        background: linear-gradient(to right, var(--main-card-bg) 0%, transparent 100%);
      }
    }
  }
}
</style>

<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.tariff-table-item {
  &__popover {
    text-align: left
    &.tooltip[x-placement^="left"] {
      +breakpoint(ms-and-down) {
        margin-right: 0;
      }
    }
    &.tooltip[x-placement^="right"] {
      +breakpoint(ms-and-down) {
        margin-left: 0;
      }
    }
    .popover-arrow {
      +breakpoint(ms-and-down) {
        display: none;
      }
    }
  }

  &__context-menu {
    +breakpoint(ms-and-down) {
      width: calc(100vw - 6rem);
    }
  }
}
</style>
